import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  toast: false,
  text: "",
  severity: "info",
}

export const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    openInfoToast: (state, action) => {
      state.text = action.payload
      state.severity = "info"
      state.toast = true
    },
    openErrorToast: (state, action) => {
      state.text = action.payload
      state.severity = "error"
      state.toast = true
    },
    closeToast: state => {
      state.toast = false
    },
  },
})

export const { openErrorToast, openInfoToast, closeToast } = toastSlice.actions

export const selectToast = state => state.toast.toast
export const selectSeverity = state => state.toast.severity
export const selectText = state => state.toast.text

export default toastSlice.reducer
