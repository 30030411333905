import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_MODULE_KEY } from "../constants/api"
import ModuleKeys from "../constants/ModuleKeys"

const initialState = {
  page: {},
  loading: true,
}

export const chisiamoSlice = createSlice({
  name: "perche_noi",
  initialState,
  reducers: {
    setChiSiamo: (state, action) => {
      state.page = action.payload
      state.loading = false
    },
    startFetchingChiSiamo: state => {
      state.loading = true
    },
  },
})

export const { setChiSiamo, startFetchingChiSiamo } = chisiamoSlice.actions

export const startFetchingChiSiamoAsync = () => async dispatch => {
  dispatch(startFetchingChiSiamo())

  const { data } = await axios.get(GET_MODULE_KEY(ModuleKeys.CHI_SIAMO))
  let toRet = {}

  data.forEach(item => {
    toRet[item.key_modulo] = item
  })

  dispatch(setChiSiamo(toRet))
}

export const selectChiSiamoPage = state => state.chisiamo.page
export const selectChiSiamoLoading = state => state.chisiamo.loading

export default chisiamoSlice.reducer
