import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_BANNER_KEY,
  GET_CHIRURGHI_HOME,
  GET_MENU,
  GET_REVIEWS_HOME,
} from "../constants/api"

import BannerKeys from "../constants/BannerKeys"

const initialState = {
  chirurgia: {},
  prefs: [],
  reviews: [],
  searchItems: [],
  chirurghi: [],
  loading: true,
  banner: null,
}

export const chirurgiaSlice = createSlice({
  name: "chirurgia",
  initialState,
  reducers: {
    setChirurgia: (state, action) => {
      state.prefs = action.payload.prefs
      state.reviews = action.payload.reviews
      state.chirurghi = action.payload.chirurghi
      state.searchItems = action.payload.searchItems
      state.banner = action.payload.banner
      state.loading = false
    },
    startFetchingChiru: state => {
      state.loading = true
    },
  },
})

export const { setChirurgia, startFetchingChiru } = chirurgiaSlice.actions

export const fetchChiruAsync = () => async dispatch => {
  dispatch(startFetchingChiru())
  const chirurghi = await axios.get(GET_CHIRURGHI_HOME)
  const reviews = await axios.get(GET_REVIEWS_HOME)
  const banner = await axios.get(GET_BANNER_KEY(BannerKeys.CHIRURGIA))
  const { data } = await axios.get(GET_MENU)
  let prefs = []
  let searchItems = []
  data.Categorie.filter(d => d.macrocategoria == "CHIRURGIA ESTETICA").forEach(
    c => {
      prefs.push({ title: c.categoria, key: c.id_sys, items: [] })
    }
  )

  data.Trattamenti.forEach(t => {
    const found = prefs.find(obj => obj.key == t.id_cat)
    if (found) {
      found.items.push(t)
      searchItems.push(t)
    }
  })

  dispatch(
    setChirurgia({
      chirurghi: chirurghi.data,
      reviews: reviews.data,
      banner: banner.data[0],
      prefs,
      searchItems,
    })
  )
}

export const selectBannerChirurgia = state => state.chirurgia.banner
export const selectChiruPrefs = state => state.chirurgia.prefs
export const selectChiruSearchItems = state => state.chirurgia.searchItems
export const selectChiruChirurghi = state => state.chirurgia.chirurghi
export const selectChiruReviews = state => state.chirurgia.reviews
export const selectChiruLoading = state => state.chirurgia.loading

export default chirurgiaSlice.reducer
