export const LOGIN_POPUP = "Hai eseguito il login"
export const PASSWORD_FORGOT_POPUP = "Hai ricevuto una mail con le istruzioni"
export const REGISTRAZIONE_POPUP =
  "grazie per esserti registrato! Riceverai una mail per confermare la tua iscrizione."
export const CONTATTO_MEDICO_POPUP = "La tua richiesta è stata inviata!"

export const CONTATTO_POPUP =
  "Richiesta inoltrata , ti contatteremo prima possibile!"
export const RECENSIONE_POPUP = "La tua recensione è stata inviata!"
export const ESPERIENZE_POPUP = "Grazie per aver condiviso la tua esperienza!"

export const ACCEPT_TERMS = "Ricordati di accettare i termini"
