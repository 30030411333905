import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_MODULE_KEY } from "../constants/api"
import ModuleKeys from "../constants/ModuleKeys"

const initialState = {
  page: {},
  loading: true,
}

export const cosaoffriamoSlice = createSlice({
  name: "cosa_offriamo",
  initialState,
  reducers: {
    setCosaOffriamo: (state, action) => {
      state.page = action.payload
      state.loading = false
    },
    startFetchingCosaOffriamo: state => {
      state.loading = true
    },
  },
})

export const {
  setCosaOffriamo,
  startFetchingCosaOffriamo,
} = cosaoffriamoSlice.actions

export const startFetchingCosaOffriamoAsync = () => async dispatch => {
  dispatch(startFetchingCosaOffriamo())

  const { data } = await axios.get(GET_MODULE_KEY(ModuleKeys.COSA_OFFRIAMO))
  let toRet = {}

  data.forEach(item => {
    toRet[item.key_modulo] = item
  })

  dispatch(setCosaOffriamo(toRet))
}

export const selectCosaOffriamoPage = state => state.cosaoffriamo.page
export const selectCosaOffriamoLoading = state => state.cosaoffriamo.loading

export default cosaoffriamoSlice.reducer
