import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_CHIRURGHI_ADDRESSES } from "../constants/api"

const initialState = {
  positions: [],
  loading: true,
}

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    startFetchingChirurghiPositions: state => {
      state.loading = true
    },
    setChirurghiPositions: (state, action) => {
      state.positions = action.payload
      state.loading = false
    },
  },
})

export const {
  setChirurghiPositions,
  startFetchingChirurghiPositions,
} = mapSlice.actions

export const fetchLocationsAsync = () => async dispatch => {
  dispatch(startFetchingChirurghiPositions())
  const { data } = await axios.get(GET_CHIRURGHI_ADDRESSES)

  dispatch(setChirurghiPositions(data))
}

export const fetchLocationsByIdAsync = id => async dispatch => {
  dispatch(startFetchingChirurghiPositions())
  const { data } = await axios.get(GET_CHIRURGHI_ADDRESSES)

  dispatch(setChirurghiPositions(data.filter(d => d.id_chi == id)))
}

export const selectMapLocations = state => state.map.positions
export const selectMapLoading = state => state.map.loading

export default mapSlice.reducer
