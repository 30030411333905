import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_TRATTAMENTI,
  POST_CHIRUGHI_SEARCH,
  POST_REVIEW,
} from "../constants/api"
import { ESPERIENZE_POPUP } from "../constants/popups"
import { openErrorToast, openInfoToast } from "./toastSlice"

const initialState = {
  loading: false,
  startUpLoading: true,
  chirurghi: [],
  trattamenti: [],
}

export const reviewsModalSlice = createSlice({
  name: "reviewsModal",
  initialState,
  reducers: {
    reviewsModalStartUpLoading: state => {
      state.startUpLoading = true
    },
    reviewsModalLoadingStart: state => {
      state.loading = true
    },
    reviewsModalLoadingEnd: state => {
      state.loading = false
    },
    setReviewsModal: (state, action) => {
      state.chirurghi = action.payload.chirurghi
      state.trattamenti = action.payload.trattamenti
      state.startUpLoading = false
    },
  },
})

export const {
  reviewsModalStartUpLoading,
  reviewsModalLoadingStart,
  reviewsModalLoadingEnd,
  setReviewsModal,
} = reviewsModalSlice.actions

export const fetchReviewsModalAsync = () => async dispatch => {
  dispatch(reviewsModalStartUpLoading())
  const chirurghi = await axios.post(POST_CHIRUGHI_SEARCH, {
    id_comune: null,
    id_trattamento: null,
    video_consulto: null,
    finanziamento: null,
    sesso: null,
  })
  const trattamenti = await axios.get(GET_TRATTAMENTI)

  dispatch(
    setReviewsModal({
      chirurghi: chirurghi.data.fun_data,
      trattamenti: trattamenti.data,
    })
  )
}

export const sendReviewAsync = ({
  titolo,
  rating,
  id_chirurgo,
  comune,
  id_trattamento,
  foto,
  foto_2,
  foto_3,
  foto_4,
  messaggio,
  id_cliente,
  cliente,
}) => async dispatch => {
  dispatch(reviewsModalLoadingStart())
  console.log("Cliente", cliente)
  try {
    const { data } = await axios.post(POST_REVIEW, {
      titolo,
      rating,
      id_chirurgo,
      comune,
      id_trattamento,
      foto,
      foto_2,
      foto_3,
      foto_4,
      messaggio,
      id_cliente,
      cliente,
    })
    if (data.fun_success) {
      dispatch(openInfoToast(ESPERIENZE_POPUP))
      dispatch(reviewsModalLoadingEnd())
    } else {
      dispatch(openErrorToast("Oops, si è verificato un errore sconosciuto"))
      dispatch(reviewsModalLoadingEnd())
    }
  } catch (e) {
    dispatch(openErrorToast("Oops, si è verificato un errore sconosciuto"))
    dispatch(reviewsModalLoadingEnd())
  }
}

export const selectReviewsModalLoading = state => state.reviewsModal.loading
export const selectReviewsModalTrattamenti = state =>
  state.reviewsModal.trattamenti
export const selectReviewsModalStartupLoading = state =>
  state.reviewsModal.startUpLoading
export const selectReviewsModalChirurghi = state => state.reviewsModal.chirurghi

export default reviewsModalSlice.reducer
