import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_REVIEWS_BY_TRATTAMENTO,
  POST_ARTICLES,
  POST_REVIEWS,
} from "../constants/api"
import { loadingStart } from "./contactFormSlice"

const initialState = {
  articles: [],
  loading: false,
  pagesTotal: null,
  currentPage: 1,
  loadingMore: false,
}

export const articlesSlice = createSlice({
  name: "articles",
  initialState,
  reducers: {
    setArticlesLoadingStart: state => {
      state.loading = true
    },
    setArticlesLoadingMore: state => {
      state.loadingMore = true
    },
    setMoreError: state => {
      state.loadingMore = false
    },
    setArticles: (state, action) => {
      state.articles = action.payload.articles
      state.pagesTotal = action.payload.pagesTotal
      state.currentPage = 1
      state.loading = false
    },
    setMoreArticles: (state, action) => {
      state.articles = state.articles.concat(action.payload.articles)
      state.currentPage = action.payload.currentPage
      state.loading = false
      state.loadingMore = false
    },
  },
})

export const {
  setArticles,
  setArticlesLoadingStart,
  setMoreArticles,
  setMoreError,
} = articlesSlice.actions

export const fetchMoreArticlesAsync = (id, table_page) => async dispatch => {
  dispatch(setArticlesLoadingStart())
  try {
    const { data } = await axios.post(POST_ARTICLES, {
      id_trattamento: id,
      table_page,
    })
    dispatch(
      setMoreArticles({
        articles: data.fun_data,
        currentPage: table_page,
      })
    )
  } catch (e) {
    dispatch(setMoreError())
  }
}

export const fetchArticlesAsync = id => async dispatch => {
  dispatch(setArticlesLoadingStart())
  try {
    const { data } = await axios.post(POST_ARTICLES, {
      id_trattamento: id || 0,
    })
    dispatch(
      setArticles({
        articles: data.fun_data || [],
        pagesTotal: data.fun_total_table_page,
      })
    )
  } catch (e) {
    dispatch(
      setArticles({
        articles: [],
        pagesTotal: 1,
      })
    )
  }
}

export const selectArticles = state => state.articles.articles
export const selectArticlesLoading = state => state.articles.loading
export const selectArticlesLoadingMore = state => state.articles.loadingMore
export const selectArticlesPagesTotal = state => state.articles.pagesTotal
export const selectArticlesCurrentPage = state => state.articles.currentPage

export default articlesSlice.reducer
