import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_MODULE_KEY } from "../constants/api"
import ModuleKeys from "../constants/ModuleKeys"

const initialState = {
  page: {},
  loading: true,
}

export const chirurgoregisterSlice = createSlice({
  name: "chirurgo_register",
  initialState,
  reducers: {
    setChirurgoRegister: (state, action) => {
      state.page = action.payload
      state.loading = false
    },
    startFetchingChirurgoRegister: state => {
      state.loading = true
    },
  },
})

export const {
  startFetchingChirurgoRegister,
  setChirurgoRegister,
} = chirurgoregisterSlice.actions

export const startFetchingChirurgoRegisterAsync = () => async dispatch => {
  dispatch(startFetchingChirurgoRegister())

  const { data } = await axios.get(GET_MODULE_KEY(ModuleKeys.CHIRURGO_REGISTER))
  let toRet = {}

  data.forEach(item => {
    toRet[item.key_modulo] = item
  })

  dispatch(setChirurgoRegister(toRet))
}

export const selectChirurgoRegisterPage = state => state.chirurgoregister.page
export const selectChirurgoRegisterLoading = state =>
  state.chirurgoregister.loading

export default chirurgoregisterSlice.reducer
