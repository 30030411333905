import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_CHIRURGO_TRATTAMENTI,
  GET_TRATTAMENTI,
  POST_MESSAGE,
} from "../constants/api"
import { CONTATTO_MEDICO_POPUP } from "../constants/popups"
import { openErrorToast, openInfoToast } from "./toastSlice"

const initialState = {
  loading: false,
  startUpLoading: true,
  trattamenti: [],
}

export const contactFormSlice = createSlice({
  name: "contactform",
  initialState,
  reducers: {
    contactFormStartUpLoading: state => {
      state.startUpLoading = true
    },
    contactFormLoadingStart: state => {
      state.loading = true
    },
    contactFormLoadingEnd: state => {
      state.loading = false
    },
    setContactFormTrattamenti: (state, action) => {
      state.trattamenti = action.payload
      state.startUpLoading = false
    },
  },
})

export const {
  contactFormLoadingStart,
  contactFormLoadingEnd,
  setContactFormTrattamenti,
  contactFormStartUpLoading,
} = contactFormSlice.actions

export const fetchContactFormTrattamentiAsync = id_sys => async dispatch => {
  dispatch(contactFormStartUpLoading())
  const data = await axios.get(GET_CHIRURGO_TRATTAMENTI(id_sys))
  dispatch(setContactFormTrattamenti(data.data))
}

export const fetchContattiTrattamentiAllAsync = () => async dispatch => {
  dispatch(contactFormStartUpLoading())
  const data = await axios.get(GET_TRATTAMENTI)
  dispatch(setContactFormTrattamenti(data.data))
}

export const sendContactAsync = (
  {
    id_user,
    sesso,
    nome,
    cognome,
    telefono,
    comune,
    email,
    id_chirurgo = null,
    motivazione = null,
    fascia_oraria = null,
    id_trattamento,
    messaggio,
  },
  onSuccess
) => async dispatch => {
  dispatch(contactFormLoadingStart())
  try {
    const { data } = await axios.post(POST_MESSAGE, {
      nome,
      cognome,
      telefono,
      comune,
      email,
      id_trattamento,
      id_chirurgo,
      fascia_oraria,
      messaggio,
      motivazione,
      sesso,
      id_user,
    })
    if (data.fun_success) {
      onSuccess()
    } else {
      dispatch(openErrorToast("oops, si è verificato un errore sconosciuto"))
      dispatch(contactFormLoadingEnd())
    }
  } catch (e) {
    dispatch(openErrorToast("oops, si è verificato un errore sconosciuto"))
    dispatch(contactFormLoadingEnd())
  }
}

export const selectFormLoading = state => state.contactForm.loading
export const selectFormStartupLoading = state =>
  state.contactForm.startUpLoading
export const selectContactFormTrattamenti = state =>
  state.contactForm.trattamenti

export default contactFormSlice.reducer
