import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { POST_CHIRUGHI_SEARCH } from "../constants/api"

const initialState = {
  chirurghi: [],
  loading: false,
  pagesTotal: null,
  currentPage: 1,
  isFetchingMore: false,
}

export const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    setChirurghi: (state, action) => {
      state.chirurghi = action.payload.chirurghi
      state.pagesTotal = action.payload.pagesTotal
      state.currentPage = 1
      state.loading = false
      state.isFetchingMore = false
    },
    startFetching: state => {
      state.loading = true
    },
    startFetchingMore: state => {
      state.fetchingMore = true
    },
    setMoreChirurghi: (state, action) => {
      state.chirurghi = [...state.chirurghi, ...action.payload.chirurghi]
      state.currentPage = action.payload.currentPage
      state.loading = false
      state.isFetchingMore = false
    },
  },
})

export const {
  setChirurghi,
  startFetching,
  startFetchingMore,
  setMoreChirurghi,
} = filterSlice.actions

export const fetchMoreChirurghiAsync = ({
  id_comune,
  id_trattamento,
  video_consulto,
  finanziamento,
  sesso,
  table_page,
}) => async dispatch => {
  dispatch(startFetchingMore())
  try {
    const chirurghi = await axios.post(POST_CHIRUGHI_SEARCH, {
      id_comune,
      id_trattamento,
      video_consulto,
      finanziamento,
      sesso,
      table_page,
    })
    dispatch(
      setMoreChirurghi({
        chirurghi: chirurghi.data.fun_data,
        currentPage: table_page,
      })
    )
  } catch (e) {
    //handle error
  }
}

export const fetchChirurghiFilterAsync = ({
  id_comune,
  id_trattamento,
  video_consulto,
  finanziamento,
  sesso,
}) => async dispatch => {
  dispatch(startFetching())
  axios
    .post(POST_CHIRUGHI_SEARCH, {
      id_comune,
      id_trattamento,
      video_consulto,
      finanziamento,
      sesso,
      table_page: 1,
    })
    .then(res => {
      dispatch(
        setChirurghi({
          chirurghi: res.data?.fun_data || [],
          pagesTotal: res.data?.fun_total_table_page || 0,
        })
      )
    })
    .catch(err => {
      dispatch(
        setChirurghi({
          chirurghi: [],
          pagesTotal: 0,
        })
      )
    })
}

export const selectChirurghiFilter = state => state.filters.chirurghi
export const selectFilterLoading = state => state.filters.loading
export const selectFilterLoadingMore = state => state.filters.isFetchingMore
export const selectFilterPage = state => state.filters.currentPage
export const selectFilterPagesTotal = state => state.filters.pagesTotal

export default filterSlice.reducer
