import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_CHIRURGHI_LITE, GET_CITIES, GET_TRATTAMENTI } from "../constants/api"

const initialState = {
  modal: false,
  trattamento: null,
  chirurgo: null,
  loading: true,
  trattamenti: [],
  chirurghi: [],
  comuni: [],
}

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.modal = true
      state.chirurgo = action.payload
      state.trattamento = null
    },
    setTrattamenti: (state, action) => {
      state.trattamenti = action.payload
      state.loading = false
    },
    setChirurghi: (state, action) => {
      state.chirurghi = action.payload
      state.loading = false
    },
    setComuni: (state, action) => {
      state.comuni = action.payload
      state.loading = false
    },
    openModalWithTrattamento: (state, action) => {
      state.modal = true
      state.chirurgo = action.payload.chirurgo
      state.trattamento = action.payload.trattamento
    },
    closeModal: (state, action) => {
      state.modal = false
    },
  },
})

export const {
  openModal,
  closeModal,
  openModalWithTrattamento,
  setTrattamenti,
  setChirurghi,
  setComuni
} = layoutSlice.actions

export const fetchLayoutTrattamentiAsync = () => async dispatch => {
  const { data } = await axios.get(GET_TRATTAMENTI)
  dispatch(setTrattamenti(data))
}

export const fetchLayoutChirurghiAsync = () => async dispatch => {
  const { data } = await axios.get(GET_CHIRURGHI_LITE)
  dispatch(setChirurghi(data))
}

export const fetchLayoutComuniAsync = () => async dispatch => {
  const { data } = await axios.get(GET_CITIES)
  dispatch(setComuni(data))
}

export const selectModal = state => state.layout.modal
export const selectLayoutTrattamenti = state => state.layout.trattamenti
export const selectLayoutLoading = state => state.layout.loading
export const selectLayoutChirurgo = state => state.layout.chirurgo
export const selectLayoutTrattamento = state => state.layout.trattamento
export const selectLayoutChirurghi = state => state.layout.chirurghi
export const selectLayoutComuni= state => state?.layout.comuni

export default layoutSlice.reducer
