import axios from "axios"

export const getRequest = async (endpoint, errorCallback) => {
  try {
    const { data } = await axios.get(endpoint)
    return data
  } catch (err) {
    errorCallback()
  }
}
