import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_BANNER_KEY,
  GET_CHIRURGHI_HOME,
  GET_MENU,
  GET_REVIEWS_HOME,
} from "../constants/api"

const initialState = {
  chirurgia: {},
  prefs: [],
  reviews: [],
  banner: null,
  searchItems: [],
  chirurghi: [],
  loading: true,
}

export const thumbSlice = createSlice({
  name: "viso",
  initialState,
  reducers: {
    setThumb: (state, action) => {
      state.prefs = action.payload.prefs
      state.reviews = action.payload.reviews
      state.chirurghi = action.payload.chirurghi
      state.searchItems = action.payload.searchItems
      state.banner = action.payload.banner
      state.loading = false
    },
    startFetchingThumb: state => {
      state.loading = true
    },
  },
})

export const { setThumb, startFetchingThumb } = thumbSlice.actions

export const fetchThumbAsync = cat => async dispatch => {
  dispatch(startFetchingThumb())
  const chirurghi = await axios.get(GET_CHIRURGHI_HOME)
  const reviews = await axios.get(GET_REVIEWS_HOME)
  const banner = await axios.get(GET_BANNER_KEY(`web-${cat}`))
  const { data } = await axios.get(GET_MENU)
  let prefs = []
  let searchItems = []
  data.Categorie.filter(d => d.categoria == cat).forEach(c => {
    prefs.push({
      title:
        c.macrocategoria == "CHIRURGIA ESTETICA"
          ? "Chirurgia Estetica " + cat
          : "Medicina Estetica " + cat,
      key: c.id_sys,
      items: [],
    })
  })

  data.Trattamenti.forEach(t => {
    const found = prefs.find(obj => obj.key == t.id_cat)
    if (found) {
      found.items.push(t)
      searchItems.push(t)
    }
  })

  dispatch(
    setThumb({
      chirurghi: chirurghi.data,
      reviews: reviews.data,
      banner: banner.data[0],
      prefs,
      searchItems,
    })
  )
}
export const selectThumbbanner = state => state.thumb.banner
export const selectThumbPrefs = state => state.thumb.prefs
export const selectThumbSearchItems = state => state.thumb.searchItems
export const selectThumbChirurghi = state => state.thumb.chirurghi
export const selectThumbReviews = state => state.thumb.reviews
export const selectThumbLoading = state => state.thumb.loading

export default thumbSlice.reducer
