import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_CITIES,
  GET_TRATTAMENTI,
  POST_CHIRUGHI_SEARCH,
} from "../constants/api"

const initialState = {
  trattamenti: [],
  city: [],
  chirurghi: null,
  loading: false,
}

export const searchSlice = createSlice({
  name: "searchSlice",
  initialState,
  reducers: {
    setTrattamenti: (state, action) => {
      state.trattamenti = action.payload
      state.loading = false
    },
    setCities: (state, action) => {
      state.city = action.payload
      state.loading = false
    },
    setChirurghi: (state, action) => {
      state.chirurghi = action.payload
      state.loading = false
    },
    resetChirurghi: state => {
      state.chirurghi = null
      state.loading = false
    },
    startFetching: state => {
      state.loading = true
    },
  },
})

export const {
  setTrattamenti,
  startFetching,
  setCities,
  setChirurghi,
  resetChirurghi,
} = searchSlice.actions

export const fetchTrattamentiAsync = () => async dispatch => {
  dispatch(startFetching())
  axios.get(GET_TRATTAMENTI).then(res => {
    dispatch(setTrattamenti(res.data))
  })
}

export const fetchCitiesAsync = () => async dispatch => {
  dispatch(startFetching())
  axios.get(GET_CITIES).then(res => {
    dispatch(setCities(res.data))
  })
}

export const fetchChirurghiAsync = ({
  id_comune,
  id_trattamento,
}) => async dispatch => {
  dispatch(startFetching())
  console.log(id_comune)
  console.log(id_trattamento)
  axios
    .post(POST_CHIRUGHI_SEARCH, {
      id_trattamento,
      id_comune,
    })
    .then(res => {
      dispatch(setChirurghi(res.data.fun_data))
    })
}

export const selectTrattamenti = state => state.search.trattamenti
export const selectCity = state => state.search.city
export const selectChirurghiSearch = state => state.search.chirurghi
export const selectTrattamentiLoading = state => state.search.loading

export default searchSlice.reducer
