import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { persistReducer, persistStore } from "redux-persist"
import logger from "redux-logger"
import reviewsReducer from "./reviewsSlice"
import searchReducer from "./searchSlice"
import filterReducer from "./filterSlice"
import chirurgoReducer from "./chirurgoPageSlice"
import menuReducer from "./menuSlice"
import homeReducer from "./homeSlice"
import contactFormReducer from "./contactFormSlice"
import toastReducer from "./toastSlice"
import footerReducer from "./footerSlice"
import trattamentoPageReducer from "./trattamentoPageSlice"
import chirurgiaReducer from "./chirurgiaSlice"
import medicinaReducer from "./medicinaSlice"
import layoutReducer from "./layoutSlice"
import thumbReducer from "./thumbSlice"
import articlesReducer from "./articlesSlice"
import thunk from "redux-thunk"
import reviewsModalReducer from "./reviewsModalSlice"
import mapReducer from "./mapSlice"
import chisiamoReducer from "./chisiamoSlice"
import cosaoffriamoReducer from "./cosaoffriamoSlice"
import chirurgoregisterReducer from "./chirurgoregisterSlice"
import userReducer, { refetchAfterRehydrate } from "./userSlice"
import createFilter from "redux-persist-transform-filter"
import storage from "redux-persist/lib/storage"

const middleware = [thunk]

if (process.env.NODE_ENV === "development") {
  //@ts-ignore
  middleware.push(logger)
}

// inizializzazione del rootReducer, esso contiene più reducer che conviene splittare logicamente  con gli slice.
// ogni slice esporta il reducer di default e viene listato qui.
const rootReducer = combineReducers({
  reviews: reviewsReducer,
  articles: articlesReducer,
  search: searchReducer,
  filters: filterReducer,
  chirurgo: chirurgoReducer,
  menu: menuReducer,
  home: homeReducer,
  toast: toastReducer,
  chirurgia: chirurgiaReducer,
  contactForm: contactFormReducer,
  trattamento: trattamentoPageReducer,
  footer: footerReducer,
  medicina: medicinaReducer,
  layout: layoutReducer,
  thumb: thumbReducer,
  reviewsModal: reviewsModalReducer,
  map: mapReducer,
  chisiamo: chisiamoReducer,
  cosaoffriamo: cosaoffriamoReducer,
  chirurgoregister: chirurgoregisterReducer,
  user: userReducer,
})

// you want to store only a subset of your state of reducer one
const saveSubsetFilter = createFilter("user", ["currentUser"])

//configurazione del persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
  transforms: [saveSubsetFilter],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

//qui inizializziamo lo store, utilizzzando la versione persistent del reducer e i middleware aggiunti all'inizio del file
const store = configureStore({
  reducer: persistedReducer,
  middleware,
})

export const persistor = persistStore(store, null, () => {
  store.dispatch(refetchAfterRehydrate())
})

export default store
