import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_MENU } from "../constants/api"

const initialState = {
  chirurgia: {},
  medicina: {},
  loading: false,
}

export const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setMenu: (state, action) => {
      state.chirurgia = action.payload.chirurgia
      state.medicina = action.payload.medicina
      state.loading = false
    },
    setLocation: (state, action) => {
      state.position = action.payload
    },

    startFetching: state => {
      state.loading = true
    },
  },
})

export const { setMenu, startFetching,setLocation } = menuSlice.actions

export const setPosition = (position) => dispatch =>{
  dispatch(setLocation(position))
}

export const fetchMenuAsync = () => async dispatch => {
  dispatch(startFetching())
  const { data } = await axios.get(GET_MENU)

  let chirurgia = {
    categorie: [],
    trattamenti: {},
  }

  let medicina = {
    categorie: [],
    trattamenti: {},
  }

  data.Categorie.forEach(c => {
    if (c.macrocategoria == "CHIRURGIA ESTETICA") {
      chirurgia.categorie.push(c)
      chirurgia.trattamenti[c.id_sys] = []
    } else if (c.macrocategoria == "MEDICINA ESTETICA") {
      medicina.categorie.push(c)
      medicina.trattamenti[c.id_sys] = []
    }
  })

  data.Trattamenti.forEach(t => {
    if (chirurgia.trattamenti[t.id_cat]) {
      chirurgia.trattamenti[t.id_cat].push(t)
    } else if (medicina.trattamenti[t.id_cat]) {
      medicina.trattamenti[t.id_cat].push(t)
    }
  })
  console.log({ alberto: chirurgia, alfredo: medicina })
  dispatch(setMenu({ chirurgia, medicina }))
}

export const selectChirurgia = state => state.menu.chirurgia
export const selectPosition = state => state.menu.position
export const selectMedicina = state => state.menu.medicina
export const selectMenuLoading = state => state.menu.loading

export default menuSlice.reducer
