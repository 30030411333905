import axios from "axios"

export const sendRequestAsync = async (
  endpoint,
  payload,
  onSuccess,
  onError,
  validator
) => {
  console.log(endpoint)
  try {
    const { data } = await axios.post(endpoint, payload)
    if (validator(data)) {
      console.log("SUCCESS", data)
      onSuccess(data)
    } else {
      console.log("error", data)
      onError(data)
    }
  } catch (err) {
    console.log(err)
    onError(err)
  }
}
