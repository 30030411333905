import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_BANNER,
  GET_CHIRURGHI_HOME,
  GET_DOMANDE,
  GET_REVIEWS_HOME,
  GET_THUMBS_HOME,
  GET_TRATTAMENTI_POPOLARI,
} from "../constants/api"

const initialState = {
  chirurghi: [],
  questions: [],
  thumbs: [],
  reviews: [],
  trattamenti: [],
  banner: null,
  loading: false,
}

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setHome: (state, action) => {
      state.banner = action.payload.banner
      state.chirurghi = action.payload.chirurghi
      state.reviews = action.payload.reviews
      state.thumbs = action.payload.thumbs
      state.trattamenti = action.payload.trattamenti
      state.questions = action.payload.questions
      state.loading = false
    },
    setChirurghi: (state, action) => {
      state.loading = false
      state.chirurghi = action.payload
    },
    setReviews: (state, action) => {
      state.loading = false
      state.reviews = action.payload
    },
    setBanner: (state, action) => {
      state.loading = false
      state.banner = action.payload
    },
    setTrattamentiHome: (state, action) => {
      state.loading = false
      state.trattamenti = action.payload
    },
    startFetchingTrattamentiHome: state => {
      state.loading = true
    },
    startFetchingHome: state => {
      state.loading = true
    },
  },
})

export const {
  setHome,
  startFetchingHome,
  setReviews,
  setChirurghi,
  setBanner,
  setTrattamentiHome,
  startFetchingTrattamentiHome,
} = homeSlice.actions

export const fetchChirurghiHome = () => async dispatch => {
  dispatch(startFetchingHome())
  const { data } = await axios.get(GET_CHIRURGHI_HOME)
  dispatch(setChirurghi(data))
}

export const fetchReviewsHome = () => async dispatch => {
  dispatch(startFetchingHome())
  const { data } = await axios.get(GET_REVIEWS_HOME)
  dispatch(setReviews(data))
}

export const fetchBannerHome = () => async dispatch => {
  dispatch(startFetchingHome())
  const { data } = await axios.get(GET_BANNER)
  dispatch(setBanner(data[0]))
}

export const fetchTrattamentiHome = () => async dispatch => {
  const { data } = await axios.get(GET_TRATTAMENTI_POPOLARI)
  console.log(data)
  dispatch(setTrattamentiHome(data))
}

export const fetchHomeAsync = () => async dispatch => {
  dispatch(startFetchingHome())
  const banner = await axios.get(GET_BANNER)
  const chirurghi = await axios.get(GET_CHIRURGHI_HOME)
  const reviews = await axios.get(GET_REVIEWS_HOME)
  const thumbs = await axios.get(GET_THUMBS_HOME)
  const trattamenti = await axios.get(GET_TRATTAMENTI_POPOLARI)
  const questions = await axios.get(GET_DOMANDE)
  dispatch(
    setHome({
      banner: banner.data[0],
      chirurghi: chirurghi.data,
      reviews: reviews.data,
      thumbs: thumbs.data,
      trattamenti: trattamenti.data,
      questions: questions.data,
    })
  )
}

export const selectBannerHome = state => state.home.banner
export const selectReviews = state => state.home.reviews
export const selectChirurghiHome = state => state.home.chirurghi
export const selectTrattamentiHome = state => state.home.trattamenti
export const selectThumbs = state => state.home.thumbs
export const selectReviewshome = state => state.home.reviews
export const selectHomeLoading = state => state.home.loading
export const selectDomande = state => state.home.questions

export default homeSlice.reducer
