// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-articoli-js": () => import("./../../../src/pages/articoli.js" /* webpackChunkName: "component---src-pages-articoli-js" */),
  "component---src-pages-capelli-js": () => import("./../../../src/pages/capelli.js" /* webpackChunkName: "component---src-pages-capelli-js" */),
  "component---src-pages-chi-siamo-js": () => import("./../../../src/pages/chi_siamo.js" /* webpackChunkName: "component---src-pages-chi-siamo-js" */),
  "component---src-pages-chirurghi-estetici-js": () => import("./../../../src/pages/chirurghi-estetici.js" /* webpackChunkName: "component---src-pages-chirurghi-estetici-js" */),
  "component---src-pages-chirurgia-estetica-js": () => import("./../../../src/pages/chirurgia-estetica.js" /* webpackChunkName: "component---src-pages-chirurgia-estetica-js" */),
  "component---src-pages-chirurgo-js": () => import("./../../../src/pages/chirurgo.js" /* webpackChunkName: "component---src-pages-chirurgo-js" */),
  "component---src-pages-chirurgo-login-js": () => import("./../../../src/pages/chirurgo_login.js" /* webpackChunkName: "component---src-pages-chirurgo-login-js" */),
  "component---src-pages-chirurgo-register-js": () => import("./../../../src/pages/chirurgo_register.js" /* webpackChunkName: "component---src-pages-chirurgo-register-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-contatti-js": () => import("./../../../src/pages/contatti.js" /* webpackChunkName: "component---src-pages-contatti-js" */),
  "component---src-pages-corpo-js": () => import("./../../../src/pages/corpo.js" /* webpackChunkName: "component---src-pages-corpo-js" */),
  "component---src-pages-cosa-offriamo-js": () => import("./../../../src/pages/cosa_offriamo.js" /* webpackChunkName: "component---src-pages-cosa-offriamo-js" */),
  "component---src-pages-esperienza-js": () => import("./../../../src/pages/esperienza.js" /* webpackChunkName: "component---src-pages-esperienza-js" */),
  "component---src-pages-esperienze-js": () => import("./../../../src/pages/esperienze.js" /* webpackChunkName: "component---src-pages-esperienze-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intimo-js": () => import("./../../../src/pages/intimo.js" /* webpackChunkName: "component---src-pages-intimo-js" */),
  "component---src-pages-medicina-estetica-js": () => import("./../../../src/pages/medicina-estetica.js" /* webpackChunkName: "component---src-pages-medicina-estetica-js" */),
  "component---src-pages-naso-js": () => import("./../../../src/pages/naso.js" /* webpackChunkName: "component---src-pages-naso-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-perche-sceglierci-js": () => import("./../../../src/pages/perche_sceglierci.js" /* webpackChunkName: "component---src-pages-perche-sceglierci-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-profilo-js": () => import("./../../../src/pages/profilo.js" /* webpackChunkName: "component---src-pages-profilo-js" */),
  "component---src-pages-sconfirmation-js": () => import("./../../../src/pages/sconfirmation.js" /* webpackChunkName: "component---src-pages-sconfirmation-js" */),
  "component---src-pages-seno-js": () => import("./../../../src/pages/seno.js" /* webpackChunkName: "component---src-pages-seno-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-pages-trattamento-articoli-js": () => import("./../../../src/pages/trattamento/articoli.js" /* webpackChunkName: "component---src-pages-trattamento-articoli-js" */),
  "component---src-pages-trattamento-articolo-js": () => import("./../../../src/pages/trattamento/articolo.js" /* webpackChunkName: "component---src-pages-trattamento-articolo-js" */),
  "component---src-pages-trattamento-chirurghi-js": () => import("./../../../src/pages/trattamento/chirurghi.js" /* webpackChunkName: "component---src-pages-trattamento-chirurghi-js" */),
  "component---src-pages-trattamento-copy-esperienze-js": () => import("./../../../src/pages/trattamento copy/esperienze.js" /* webpackChunkName: "component---src-pages-trattamento-copy-esperienze-js" */),
  "component---src-pages-trattamento-copy-prepost-js": () => import("./../../../src/pages/trattamento copy/prepost.js" /* webpackChunkName: "component---src-pages-trattamento-copy-prepost-js" */),
  "component---src-pages-trattamento-costi-js": () => import("./../../../src/pages/trattamento/costi.js" /* webpackChunkName: "component---src-pages-trattamento-costi-js" */),
  "component---src-pages-trattamento-esperienza-js": () => import("./../../../src/pages/trattamento/esperienza.js" /* webpackChunkName: "component---src-pages-trattamento-esperienza-js" */),
  "component---src-pages-trattamento-esperienze-js": () => import("./../../../src/pages/trattamento/esperienze.js" /* webpackChunkName: "component---src-pages-trattamento-esperienze-js" */),
  "component---src-pages-trattamento-index-js": () => import("./../../../src/pages/trattamento/index.js" /* webpackChunkName: "component---src-pages-trattamento-index-js" */),
  "component---src-pages-trattamento-prepost-js": () => import("./../../../src/pages/trattamento/prepost.js" /* webpackChunkName: "component---src-pages-trattamento-prepost-js" */),
  "component---src-pages-trattamento-video-js": () => import("./../../../src/pages/trattamento/video.js" /* webpackChunkName: "component---src-pages-trattamento-video-js" */),
  "component---src-pages-videoconsulto-js": () => import("./../../../src/pages/videoconsulto.js" /* webpackChunkName: "component---src-pages-videoconsulto-js" */),
  "component---src-pages-viso-js": () => import("./../../../src/pages/viso.js" /* webpackChunkName: "component---src-pages-viso-js" */)
}

