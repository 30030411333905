import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_TRATTAMENTO_VIDEOS,
  GET_TRATTAMENTO_PREPOST,
  GET_TRATTAMENTO_REVIEWS,
  GET_TREATMENT,
  GET_TRATTAMENTO_ARTICLES,
  GET_TRATTAMENTO_COSTI,
  GET_TRATTAMENTO_ARTICLES_SUGGESTED,
  GET_TRATTAMENTO_CHIRURGHI,
} from "../constants/api"
import _ from "lodash"

const initialState = {
  prepost: [],
  videos: [],
  reviews: [],
  articles: [],
  costi: [],
  chirurghi: [],
  previewArticle: null,
  suggestedArticles: [],
  previewEsperienza: null,
  trattamento: null,
  loading: true,
}

export const trattamentoPageSlice = createSlice({
  name: "trattamentoPage",
  initialState,
  reducers: {
    setTrattamento: (state, action) => {
      state.trattamento = action.payload.trattamento
      state.prepost = action.payload.prepost
      state.videos = action.payload.videos
      state.reviews = action.payload.reviews
      state.suggestedArticles = action.payload.suggestedArticles
      state.articles = action.payload.articles
      state.costi = action.payload.costi
      state.chirurghi = action.payload.chirurghi
      state.loading = false
    },
    setReviews: (state, action) => {
      state.reviews = action.payload
      state.loading = false
    },
    setChirurghi: (state, action) => {
      state.chirurghi = action.payload
      state.loading = false
    },
    setPreviewArticle: (state, action) => {
      state.previewArticle = action.payload
    },
    setPreviewEsperienza: (state, action) => {
      console.log({action})
      state.previewEsperienza = action.payload
    },
    startLoadingTrattamentoPage: state => {
      state.loading = true
    },
  },
})

export const {
  setChirurghi,
  setReviews,
  setPrePost,
  setArticles,
  setVideos,
  setTrattamento,
  startLoadingTrattamentoSubPage,
  startLoadingTrattamentoPage,
  setPreviewArticle,
  setPreviewEsperienza,
} = trattamentoPageSlice.actions

export const fetchTrattamento = id => async dispatch => {
  dispatch(startLoadingTrattamentoPage())
  const [
    trattamento,
    prepost,
    videos,
    reviews,
    costi,
    articles,
    chirurghi,
  ] = await Promise.all([
    axios.get(GET_TREATMENT(id)),
    axios.get(GET_TRATTAMENTO_PREPOST(id)),
    axios.get(GET_TRATTAMENTO_VIDEOS(id)),
    axios.get(GET_TRATTAMENTO_REVIEWS(id)),
    axios.get(GET_TRATTAMENTO_COSTI(id)),
    axios.get(GET_TRATTAMENTO_ARTICLES(id)),
    axios.get(GET_TRATTAMENTO_CHIRURGHI(id)),
  ])

  const trattamentoData = trattamento.data[0]
  const suggestedArticles = await axios.get(
    GET_TRATTAMENTO_ARTICLES_SUGGESTED(
      trattamentoData.id_cat,
      trattamentoData.id_sys
    )
  )

  dispatch(
    setTrattamento({
      trattamento: trattamentoData,
      prepost: prepost.data,
      videos: videos.data,
      reviews: reviews.data,
      articles: articles.data,
      suggestedArticles: suggestedArticles.data,
      costi: costi.data,
      chirurghi: chirurghi.data,
    })
  )
}

export const fetchTrattamentoPrePost = id => async dispatch => {
  dispatch(startLoadingTrattamentoPage())
  const { data } = await axios.get(GET_TRATTAMENTO_PREPOST(id))
  dispatch(setPrePost(data))
}

export const fetchTrattamentoVideos = id => async dispatch => {
  dispatch(startLoadingTrattamentoSubPage())
  const { data } = await axios.get(GET_TRATTAMENTO_VIDEOS(id))
  dispatch(setVideos(data))
}

export const fetchTrattamentoChirurghi = id => async dispatch => {
  dispatch(startLoadingTrattamentoPage())
  const { data } = await axios.get(GET_TRATTAMENTO_CHIRURGHI(id))
  dispatch(setChirurghi(data))
}

export const fetchTrattamentoReviews = id => async dispatch => {
  dispatch(startLoadingTrattamentoPage())
  const { data } = await axios.get(GET_TRATTAMENTO_REVIEWS(id))
  dispatch(setReviews(data))
}

export const selectTrattamentoPageLoading = state => state.trattamento.loading
export const selectTrattamentoPage = state => state.trattamento.trattamento
export const selectTrattamentoPageCosti = state => state.trattamento.costi
export const selectTrattamentoPageChirurghi = state =>
  state.trattamento.chirurghi
export const selectTrattamentoPrePost = state => state.trattamento.prepost
export const selectTrattamentoReviews = state => state.trattamento.reviews
export const selectPreviewArticle = state => state.trattamento.previewArticle
export const selectTrattamentoVideos = state => state.trattamento.videos
export const selectTrattamentoArticles = state => state.trattamento.articles
export const selectSuggestedArticles = state =>
  state.trattamento.suggestedArticles
export const selectPreviewEsperienza = state =>
  state.trattamento.previewEsperienza

export default trattamentoPageSlice.reducer
