import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_BANNER_KEY,
  GET_CHIRURGHI_HOME,
  GET_MENU,
  GET_REVIEWS_HOME,
} from "../constants/api"
import BannerKeys from "../constants/BannerKeys"

const initialState = {
  chirurgia: {},
  prefs: [],
  reviews: [],
  banner: null,
  searchItems: [],
  chirurghi: [],
  loading: true,
}

export const medicinaSlice = createSlice({
  name: "medicina",
  initialState,
  reducers: {
    setMedicina: (state, action) => {
      state.prefs = action.payload.prefs
      state.reviews = action.payload.reviews
      state.searchItems = action.payload.searchItems
      state.banner = action.payload.banner
      state.chirurghi = action.payload.chirurghi
      state.loading = false
    },
    startFetchingMed: state => {
      state.loading = true
    },
  },
})

export const { setMedicina, startFetchingMed } = medicinaSlice.actions

export const fetchMedAsync = () => async dispatch => {
  dispatch(startFetchingMed())
  const chirurghi = await axios.get(GET_CHIRURGHI_HOME)
  const reviews = await axios.get(GET_REVIEWS_HOME)
  const banner = await axios.get(GET_BANNER_KEY(BannerKeys.MEDICINA))
  const { data } = await axios.get(GET_MENU)
  console.log("BANNERR", banner)
  let prefs = []
  let searchItems = []
  data.Categorie.filter(d => d.macrocategoria == "MEDICINA ESTETICA").forEach(
    c => {
      prefs.push({ title: c.categoria, key: c.id_sys, items: [] })
    }
  )

  data.Trattamenti.forEach(t => {
    const found = prefs.find(obj => obj.key == t.id_cat)
    if (found) {
      found.items.push(t)
      searchItems.push(t)
    }
  })

  dispatch(
    setMedicina({
      chirurghi: chirurghi.data,
      reviews: reviews.data,
      banner: banner.data[0],
      prefs,
      searchItems,
    })
  )
}

export const selectBannerMedicina = state => state.medicina.banner
export const selectMedPrefs = state => state.medicina.prefs
export const selectMedSearchItems = state => state.medicina.searchItems
export const selectMedChirurghi = state => state.medicina.chirurghi
export const selectMedReviews = state => state.medicina.reviews
export const selectMedLoading = state => state.medicina.loading

export default medicinaSlice.reducer
