import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_FOOTER_TRATTAMENTI,
  GET_FOOTER_ARTICOLI,
  GET_FOOTER_COMUNI,
} from "../constants/api"

const initialState = {
  articoli: [],
  trattamenti: [],
  comuni: [],
  loading: true,
}

export const footerSlice = createSlice({
  name: "footer",
  initialState,
  reducers: {
    startFetchingFooter: state => {
      state.loading = true
    },
    setFooter: (state, action) => {
      state.loading = false
      state.trattamenti = action.payload.trattamenti
      state.articoli = action.payload.articoli
      state.comuni = action.payload.comuni
    },
  },
})

export const { setFooter, startFetchingFooter } = footerSlice.actions

export const fetchFooterAsync = (
  latitudine = 41.0627,
  longitudine = 14.2743
) => async dispatch => {
  dispatch(startFetchingFooter())
  const trattamenti = await axios.get(GET_FOOTER_TRATTAMENTI)
  const articoli = await axios.get(GET_FOOTER_ARTICOLI)
  let comuni
  if (latitudine && longitudine) {
    comuni = await axios.post(GET_FOOTER_COMUNI, {
      latitudine,
      longitudine,
    })
  } else {
    comuni = await axios.get(GET_FOOTER_COMUNI)
  }

  dispatch(
    setFooter({
      trattamenti: trattamenti.data,
      articoli: articoli.data,
      comuni: comuni.data.fun_data || comuni.data,
    })
  )
}

export const selectFooterTrattamenti = state => state.footer.trattamenti
export const selectFooterComuni = state => state.footer.comuni
export const selectFooterLoading = state => state.footer.loading
export const selectFooterArticoli = state => state.footer.articoli

export default footerSlice.reducer
