import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { GET_REVIEWS_BY_TRATTAMENTO, POST_REVIEWS } from "../constants/api"
import { loadingStart } from "./contactFormSlice"

const initialState = {
  reviews: [],
  loading: false,
  preview: false,
  pagesTotal: null,
  currentPage: 1,
  loadingMore: false,
}

export const reviewsSlice = createSlice({
  name: "reviews",
  initialState,
  reducers: {
    setReviewsLoadingStart: state => {
      state.loading = true
    },
    setReviewsLoadingMore: state => {
      state.loadingMore = true
    },
    setMoreError: state => {
      state.loadingMore = false
    },
    setReviews: (state, action) => {
      state.reviews = action.payload.reviews
      state.pagesTotal = action.payload.pagesTotal
      state.currentPage = 1
      state.preview = false
      state.loading = false
    },
    setMoreReviews: (state, action) => {
      state.reviews = state.reviews.concat(action.payload.reviews)
      state.currentPage = action.payload.currentPage
      state.loading = false
      state.loadingMore = false
    },
    setPreview: (state, action) => {
      state.reviews = action.payload
      state.preview = true
    },
  },
})

export const {
  setReviews,
  setPreview,
  setReviewsLoadingStart,
  setMoreReviews,
  setMoreError,
} = reviewsSlice.actions

export const fetchMoreReviewsAsync = (id, table_page) => async dispatch => {
  dispatch(setReviewsLoadingStart())
  try {
    const { data } = await axios.post(POST_REVIEWS, {
      id_trattamento: id,
      table_page,
    })
    dispatch(
      setMoreReviews({
        reviews: data.fun_data,
        currentPage: table_page,
      })
    )
  } catch (e) {
    dispatch(setMoreError())
  }
}

export const fetchReviewsAsync = id => async dispatch => {
  dispatch(setReviewsLoadingStart())
  try {
    const { data } = await axios.post(POST_REVIEWS, {
      id_trattamento: id,
    })
    dispatch(
      setReviews({
        reviews: data.fun_data || [],
        pagesTotal: data.fun_total_table_page,
      })
    )
  } catch (e) {
    dispatch(
      setReviews({
        reviews: [],
        pagesTotal: 1,
      })
    )
  }
}

export const selectReviews = state => state.reviews.reviews
export const selectPreview = state => state.reviews.preview
export const selectReviewsLoading = state => state.reviews.loading
export const selectReviewsLoadingMore = state => state.reviews.loadingMore
export const selectReviewsPagesTotal = state => state.reviews.pagesTotal
export const selectReviewsCurrentPage = state => state.reviews.currentPage

export default reviewsSlice.reducer
