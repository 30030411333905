const BASE_URL = "https://backend.chirurghiestetici.it/api"

export const USER_LOGIN = `${BASE_URL}/userLogin`

export const PASSWORD_FORGOT = `${BASE_URL}/SendPasswordDimenticata`

export const REGISTER_USER = `${BASE_URL}/RegistrationUser`

export const GET_NOTIFICATIONS = id => `${BASE_URL}/notifiche/${id}`

export const GET_RICHIESTE = id => `${BASE_URL}/richieste/${id}/0`

export const UPDATE_PASSWORD = `${BASE_URL}/updatepassword`

export const GET_RISPOSTE_FAQ = (idDomanda, idtrattamento) =>
  `${BASE_URL}/risposte/${idDomanda}/${idtrattamento}`

export const GET_PREFERENZE = id => `${BASE_URL}/preferenzecontatto/${id}`

export const GET_USER_REVIEWS = id => `${BASE_URL}/recensioni/0/0/${id}`

export const POST_RIMUOVI_PREFERENZA = `${BASE_URL}/rimuovipreferenza`

export const POST_USER_UPDATE = `${BASE_URL}/UpdateUser`

export const POST_PREFERENZE = `${BASE_URL}/preferenzecontatto`

export const GET_CHIRURGHI_LITE = `${BASE_URL}/chirurghilite`

export const GET_TREATMENT = i => `${BASE_URL}/trattamenti/${i}`

export const GET_BANNER = `${BASE_URL}/banner`

export const GET_BANNER_KEY = key => `${BASE_URL}/banner/${key}`

export const GET_MODULE_KEY = key => `${BASE_URL}/moduli/${key}`

export const GET_TRATTAMENTI = `${BASE_URL}/trattamentisearch`

export const GET_CITIES = `${BASE_URL}/comunisearch`

export const POST_CHIRUGHI_SEARCH = `${BASE_URL}/chirurghisearch`

export const GET_MENU = `${BASE_URL}/menu`

export const GET_DOMANDE = `${BASE_URL}/domande`

export const GET_RISPOSTE = (idDomanda, idTrattamento) =>
  `${BASE_URL}/Risposte/${idDomanda}/${idTrattamento}`

export const MENU_CATEGORIE_CHIRURGIA = `${BASE_URL}/menucategorie/chirurgia`

export const MENU_CATEGORIE_MEDICINA = `${BASE_URL}/menucategorie/medicina`

export const GET_CHIRURGHI_HOME = `${BASE_URL}/chirurghihome`

export const GET_REVIEWS_HOME = `${BASE_URL}/recensioni`

export const GET_CHIRURGHI_ADDRESSES = `${BASE_URL}/chirurghiindirizzi`

export const GET_REVIEWS_BY_TRATTAMENTO = id => `${BASE_URL}/recensioni/${id}`

export const POST_REVIEWS = `${BASE_URL}/recensioni`

export const POST_ARTICLES = `${BASE_URL}/articoli`

export const GET_THUMBS_HOME = `${BASE_URL}/categoriehome`

export const GET_TRATTAMENTI_POPOLARI = `${BASE_URL}/trattamentipopolari`

export const GET_CHIRURGO = id => `${BASE_URL}/chirurghi/${id}`

export const GET_CHIRURGO_REVIEWS = id => `${BASE_URL}/recensioni/0/${id}`

export const GET_TRATTAMENTO_REVIEWS = id => `${BASE_URL}/recensioni/${id}/`

export const GET_TRATTAMENTO_ARTICLES = id => `${BASE_URL}/articoli/${id}/`

export const GET_TRATTAMENTO_ARTICLES_SUGGESTED = (idCategoria, idArticolo) =>
  `${BASE_URL}/articolisimili/${idCategoria}/${idArticolo}`

export const GET_TRATTAMENTO_PREPOST = id => `${BASE_URL}/fotoprepost/${id}/`

export const GET_TRATTAMENTO_CHIRURGHI = id =>
  `${BASE_URL}/chirurghitrattamento/${id}/`

export const GET_TRATTAMENTO_COSTI = id =>
  `${BASE_URL}/chirurghitrattamenti/0/${id}/`

export const GET_TRATTAMENTO_VIDEOS = id => `${BASE_URL}/video/${id}/`

export const GET_CHIRURGO_TRATTAMENTI = id =>
  `${BASE_URL}/chirurghitrattamenti/${id}`

export const GET_CHIRURGO_VIDEOS = id => `${BASE_URL}/video/0/${id}/`

export const GET_CHIRURGO_PHOTOS = id => `${BASE_URL}/fotochirurgo/${id}/`

export const GET_CHIRURGO_PROMO = id => `${BASE_URL}/chirurghipromozioni/${id}/0`

export const GET_CHIRURGO_PREPOST = id => `${BASE_URL}/fotoprepost/0/${id}`

export const POST_MESSAGE = `${BASE_URL}/sendcontatti`

export const GET_MODULO = key => `${BASE_URL}/ModuliTrattamentiProvincie/${key}`

export const POST_REVIEW = `${BASE_URL}/sendesperienze`

export const GET_COMMENTS = id => `${BASE_URL}/commenti/${id}`

export const GET_FOOTER_ARTICOLI = `${BASE_URL}/articolirecenti`

export const GET_FOOTER_COMUNI = `${BASE_URL}/comuniFooter`

export const GET_FOOTER_TRATTAMENTI = `${BASE_URL}/trattamentipopolarifooter`
