import React from "react"
import { Provider } from "react-redux"

import store, { persistor } from "./src/redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>{element}</GoogleReCaptchaProvider></PersistGate>
    </Provider>
  )
}
