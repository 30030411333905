import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import {
  GET_CHIRURGO_PREPOST,
  GET_CHIRURGO_REVIEWS,
  GET_CHIRURGO_TRATTAMENTI,
  GET_CHIRURGO_VIDEOS,
  GET_CHIRURGO_PHOTOS,
  GET_CHIRURGO,
  GET_CHIRURGHI_ADDRESSES, GET_CHIRURGO_PROMO
} from "../constants/api"

const initialState = {
  chirurgo: null,
  loading: true,
  trattamenti: [],
  reviews: [],
  prepost: [],
  videos: [],
  photos: [],
  promozioni: [],
}

export const chirurgoPageSlice = createSlice({
  name: "chirurgoPage",
  initialState,
  reducers: {
    setChirurgo: (state, action) => {
      state.chirurgo = action.payload.chirurgo
      state.reviews = action.payload.reviews
      state.trattamenti = action.payload.trattamenti
      state.prepost = action.payload.prepost
      state.videos = action.payload.videos
      state.photos = action.payload.photos
      state.promozioni = action.payload.promozioni
      state.loading = false
    },
    startFetching: state => {
      state.loading = true
    },
  },
})

export const { setChirurgo, startFetching } = chirurgoPageSlice.actions

export const fetchChirurgoPageAsync = id_sys => async dispatch => {
  dispatch(startFetching())
  const reviews = await axios.get(GET_CHIRURGO_REVIEWS(id_sys))
  const chirurgo = await axios.get(GET_CHIRURGO(id_sys))
  const indirizzi = await axios.get(GET_CHIRURGHI_ADDRESSES)
  const trattamenti = await axios.get(GET_CHIRURGO_TRATTAMENTI(id_sys))
  const prepost = await axios.get(GET_CHIRURGO_PREPOST(id_sys))
  const videos = await axios.get(GET_CHIRURGO_VIDEOS(id_sys))
  const photo = await axios.get(GET_CHIRURGO_PHOTOS(id_sys))
  const promo = await axios.get(GET_CHIRURGO_PROMO(id_sys))
  const sedi = indirizzi.data
    .filter(add => add.id_chi === chirurgo?.data?.[0]?.id_sys)
    .map(sede => ({
      nome: sede.citta + ", " + sede.nome_sede,
      indirizzo: sede.indirizzo + ", " + sede.citta + ", " + sede.cap,
    }))

  dispatch(
    setChirurgo({
      reviews: reviews.data,
      chirurgo: { ...chirurgo.data[0], sedi },
      trattamenti: trattamenti.data,
      prepost: prepost.data,
      videos: videos.data,
      photos: photo.data,
      promozioni:promo.data
    })
  )
}

export const selectChirurgoPageChirurgo = state => state.chirurgo.chirurgo
export const selectChirurgoPagePhotos = state => state.chirurgo.photos
export const selectChirurgoPageVideos = state => state.chirurgo.videos
export const selectChirurgoPagePrepost = state => state.chirurgo.prepost
export const selectChirurgoPageReviews = state => state.chirurgo.reviews
export const selectChirurgoPageTrattamenti = state => state.chirurgo.trattamenti
export const selectChirurgoPagePromozioni = state => state.chirurgo.promozioni
export const selectChirurgoLoading = state => state.chirurgo.loading

export default chirurgoPageSlice.reducer
