import { createSlice } from "@reduxjs/toolkit"
import {
  GET_NOTIFICATIONS,
  GET_PREFERENZE,
  GET_RICHIESTE,
  GET_USER_REVIEWS, PASSWORD_FORGOT,
  POST_PREFERENZE,
  POST_RIMUOVI_PREFERENZA,
  POST_USER_UPDATE,
  REGISTER_USER,
  UPDATE_PASSWORD,
  USER_LOGIN
} from "./../constants/api"
import { sendRequestAsync } from "../functions/sendRequestAsync"
import { openErrorToast, openInfoToast } from "./toastSlice"
import { getRequest } from "../functions/getRequest"
import store from "./store"
import axios from "axios"

const initialState = {
  currentUser: null,
  notifiche: [],
  preferenze: [],
  esperienze: [],
  richieste: [],
  preferenzaLoading: null,
  userFetching: false,
}

export const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    userFetching: state => {
      state.userFetching = true
    },
    preferenzaLoading: (state, action) => {
      state.preferenzaLoading = action.payload
    },
    addPreferenza: (state, action) => {
      state.userFetching = false
      state.preferenze = [...state.preferenze, action.payload]
      state.preferenzaLoading = null
    },
    removePreferenza: (state, action) => {
      state.userFetching = false
      state.preferenze = state.preferenze.filter(
        p => p.id_tra != action.payload
      )
      state.preferenzaLoading = null
    },
    loginSuccess: (state, action) => {
      state.currentUser = action.payload.currentUser
      state.notifiche = action.payload.notifiche
      state.preferenze = action.payload.preferenze
      state.richieste = action.payload.richieste
      state.esperienze = action.payload.esperienze
      state.userFetching = false
    },
    afterRehydrate: (state, action) => {
      state.notifiche = action.payload.notifiche
      state.preferenze = action.payload.preferenze
      state.richieste = action.payload.richieste
      state.esperienze = action.payload.esperienze
      state.userFetching = false
    },
    refetchUserSuccess: (state, action) => {
      state.currentUser = action.payload
      state.userFetching = false
    },
    updateUserEmail: (state, action) => {
      state.currentUser.user_email = action.payload
      state.userFetching = false
    },
    updateUserNickname: (state, action) => {
      state.currentUser.user_nickname = action.payload
      state.userFetching = false
    },
    updateUserNome: (state, action) => {
      state.currentUser.user_name = action.payload
      state.userFetching = false
    },
    updateUserCittà: (state, action) => {
      state.currentUser.user_citta = action.payload
      state.userFetching = false
    },
    updateNotifiche: (state, action) => {
      state.currentUser.notifiche = action.payload
    },
    updateRichieste: (state, action) => {
      state.currentUser.richieste = action.payload
    },
    updateUserSesso: (state, action) => {
      state.currentUser.user_sesso = action.payload
      state.userFetching = false
    },
    updateUserImage: (state, action) => {
      state.currentUser.user_image = action.payload
      state.userFetching = false
    },
    loginError: state => {
      state.currentUser = null
      state.userFetching = false
    },
    logoutSuccess: state => {
      state.currentUser = null
      state.preferenze = []
      state.richieste = []
      state.notifiche = []
    },
    addError: state => {
      state.userFetching = false
    },
    updatePassword: (state, action) => {
      state.currentUser.password = action.payload
      state.userFetching = false
    },
  },
})

export const {
  loginError,
  loginSuccess,
  logoutSuccess,
  userFetching,
  updateUserEmail,
  updateUserNickname,
  updateUserNome,
  addError,
  updateUserSesso,
  addPreferenza,
  updateUserCittà,
  removePreferenza,
  updateUserImage,
  refetchUserSuccess,
  preferenzaLoading,
  afterRehydrate,
  updateNotifiche,
  updateRichieste,
  updatePassword,
} = userSlice.actions

export const updatePasswordAsync = payload => async dispatch => {
  dispatch(userFetching())
  await sendRequestAsync(
    UPDATE_PASSWORD,
    payload,
    () => {
      dispatch(updatePassword(payload.new_password))
      dispatch(openInfoToast("Password aggiornata con successo"))
    },
    () => {
      dispatch(openErrorToast("Si è verificato un errore"))
      dispatch(addError())
    },
    data => data.fun_success
  )
}

export const refetchUserAsync = user => async dispatch => {
  dispatch(userFetching())
  const payload = { email: user.user_email, password: user.password }
  await sendRequestAsync(
    USER_LOGIN,
    payload,
    data => {
      dispatch(refetchUserSuccess({ ...data, password: user.password }))
    },
    () => {
      dispatch(loginError())
    },
    data => data.user_login
  )
}

export const registerUserAsync = (payload, callback) => async dispatch => {
  dispatch(userFetching())
  console.log("REGISTER")
  await sendRequestAsync(
    REGISTER_USER,
    payload,
    async () => {
      const { data } = await axios.post(USER_LOGIN, {
        email: payload.email,
        password: payload.password,
      })
      dispatch(loginHelperFunction({ ...data, password: payload.password }))
      callback()
    },
    error => {
      console.log("error", error)
      dispatch(
        openErrorToast(
          error?.fun_error_description
            ? error?.fun_error_description
            : "Si è verificato un errore."
        )
      )
      dispatch(loginError(payload))
    },
    data => data.fun_success
  )
}

export const updateUserImageAsync = (immagine, user) => async dispatch => {
  dispatch(userFetching())
  await sendRequestAsync(
    POST_USER_UPDATE,
    { immagine, id_user: user.user_id },
    data => {
      dispatch(refetchUserAsync(user))
      dispatch(openInfoToast("Il tuo profilo è stato aggiornato"))
    },
    () => {
      dispatch(openErrorToast("Il tuo profilo non è stato aggiornato"))
      dispatch(loginError())
    },
    data => data.fun_success
  )
}

export const updateUserAsync = (payload, user) => async dispatch => {
  dispatch(userFetching())

  await sendRequestAsync(
    POST_USER_UPDATE,
    { ...payload, id_user: user.user_id },
    data => {
      if (payload.email && payload.email !== user.user_email) {
        dispatch(updateUserEmail(payload.email))
      }
      if (payload.nome && payload.nome !== user.user_name) {
        dispatch(updateUserNome(payload.nome))
      }
      if (payload.nickname && payload.nickname !== user.user_nickname) {
        dispatch(updateUserNickname(payload.nickname))
      }
      if (payload.comune && payload.comune !== user.user_citta) {
        dispatch(updateUserCittà(payload.comune))
      }
      if (payload.sesso && payload.sesso !== user.user_sesso) {
        dispatch(updateUserSesso(payload.sesso))
      }
      dispatch(openInfoToast("Il tuo profilo è stato aggiornato"))
    },
    () => {
      dispatch(openErrorToast("Il tuo profilo non è stato aggiornato"))
      dispatch(loginError())
    },
    data => data.fun_success
  )
}

export const refetchAfterRehydrate = () => async dispatch => {
  //data = user Dat
  const state = store.getState()
  if (!state.user.currentUser) {
    return
  }
  const userId = state.user.currentUser.user_id

  console.log("userId", userId)
  const notifications = await getRequest(GET_NOTIFICATIONS(userId), () =>
    dispatch(openErrorToast("oops, si è verificato un errore"))
  )
  const preferenze = await getRequest(GET_PREFERENZE(userId), () =>
    dispatch(openErrorToast("oops, si è verificato un errore"))
  )
  const richieste = await getRequest(GET_RICHIESTE(userId), () =>
    dispatch(openErrorToast("oops, si è verificato un errore"))
  )
  const esperienze = await getRequest(GET_USER_REVIEWS(userId), () =>
    dispatch(openErrorToast("oops, si è verificato un errore"))
  )

  dispatch(
    afterRehydrate({
      notifiche: notifications,
      preferenze: preferenze,
      richieste: richieste,
      esperienze: esperienze,
    })
  )
}

const loginHelperFunction = data => async dispatch => {
  //data = user Data
  const currentUser = data
  console.log("currentuser", currentUser)
  const notifications = await getRequest(
    GET_NOTIFICATIONS(currentUser.user_id),
    () => dispatch(openErrorToast("oops, si è verificato un errore"))
  )
  const preferenze = await getRequest(GET_PREFERENZE(currentUser.user_id), () =>
    dispatch(openErrorToast("oops, si è verificato un errore"))
  )
  const richieste = await getRequest(GET_RICHIESTE(currentUser.user_id), () =>
    dispatch(openErrorToast("oops, si è verificato un errore"))
  )
  const esperienze = await getRequest(
    GET_USER_REVIEWS(currentUser.user_id),
    () => dispatch(openErrorToast("oops, si è verificato un errore"))
  )

  dispatch(
    loginSuccess({
      currentUser: currentUser,
      notifiche: notifications,
      preferenze: preferenze,
      richieste: richieste,
      esperienze: esperienze,
    })
  )
}

export const fetchNotificheAsync = id => async dispatch => {
  const { data } = await getRequest(GET_NOTIFICATIONS(id), () =>
    dispatch(openErrorToast("oops, si è verificato un errore"))
  )
  dispatch(updateNotifiche(data))
}

export const fetchRichiesteAsync = id => async dispatch => {
  const { data } = await getRequest(GET_RICHIESTE(id), () =>
    dispatch(openErrorToast("oops, si è verificato un errore"))
  )
  dispatch(updateRichieste(data))
}

export const rimuoviPreferenzaAsync = (
  payload,
  successCallback,
  errorCallback
) => async dispatch => {
  dispatch(preferenzaLoading(payload.id_trattamento))
  console.log(payload)
  await sendRequestAsync(
    POST_RIMUOVI_PREFERENZA,
    {
      id_utente: payload.id_utente,
      id_trattamento: payload.id_trattamento,
    },
    () => {
      successCallback()
      console.log("CALLBACK")
      dispatch(removePreferenza(payload.id_trattamento))
    },
    () => {
      errorCallback()
      dispatch(addError())
    },
    data => data.fun_success
  )
}

export const addPreferenzaAsync = (
  payload,
  successCallback,
  errorCallback
) => async dispatch => {
  dispatch(preferenzaLoading(payload.id_trattamento))
  sendRequestAsync(
    POST_PREFERENZE,
    {
      id_contatto: payload.id_contatto,
      id_trattamento: payload.id_trattamento,
    },
    () => {
      dispatch(
        addPreferenza({
          id_contatto: payload.id_contatto,
          id_tra: payload.id_trattamento,
          trattamento: payload.trattamento,
          id_sys: 1,
        })
      )
      successCallback()
    },
    () => {
      errorCallback()
      dispatch(addError())
    },
    data => data.fun_success
  )
}

export const loginUserAsync = (payload, callback) => async dispatch => {
  dispatch(userFetching())

  await sendRequestAsync(
    USER_LOGIN,
    payload,
    data => {
      dispatch(loginHelperFunction({ ...data, password: payload.password }))
      callback()
    },
    err => {
      console.log(err)
      dispatch(openErrorToast("Oops, si è verificato un errore"))
      dispatch(loginError())
    },
    data => data.user_login
  )
}

export const selectPreferenzaLoading = state => state.user.preferenzaLoading
export const selectCurrentUser = state => state.user.currentUser
export const selectUserPref = state => state.user.preferenze
export const selectUserNotifiche = state => state.user.notifiche
export const selectUserRichieste = state => state.user.richieste
export const selectUserEsperienze = state => state.user.esperienze

export default userSlice.reducer
